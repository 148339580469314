import {
  CREATING_ROOM,
  UPDATING_ROOM,
  ROOM_CREATED,
  ROOM_UPDATED,
  ERROR_ROOM_CREATE,
  UPDATED_RECENTLY,
  SET_UPDATED_RECENTLY_TO_FALSE,
} from "./types";
import axios from "axios";
import { BASE_URL } from "../../urls";
import { message } from "antd";

export const setUpdatedRecentlyToFalse = () => async (dispatch) => {
  dispatch({
    type: SET_UPDATED_RECENTLY_TO_FALSE,
  });
};

export const createEntity =
  (formData, tokenData, exitNow, history) => async (dispatch) => {
    dispatch({
      type: CREATING_ROOM,
    });

    let data = Object.fromEntries(
      Object.entries(formData)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== "")
        .filter(([_, v]) => v !== undefined)
    );

    let webKitFormData = new FormData();

    if (data.facilities) {
      for (let i = 0; i < data.facilities.length; i++) {
        webKitFormData.append("facilities[]", data.facilities[i]);
      }
    }
    if (data.faqs) {
      for (let i = 0; i < data.faqs.length; i++) {
        webKitFormData.append("faqs[]", data.faqs[i]);
      }
    }
    if (data.group_faqs) {
      for (let i = 0; i < data.group_faqs.length; i++) {
        webKitFormData.append("group_faqs[]", data.group_faqs[i]);
      }
    }
    if (data.facts) {
      let factsTitle = data.facts.map((item) => item.fact_title);
      let factsValue = data.facts.map((item) => item.fact_value);
      for (let i = 0; i < factsTitle.length; i++) {
        webKitFormData.append("facts[" + i + "][fact_title]", factsTitle[i]);
      }
      for (let i = 0; i < factsValue.length; i++) {
        webKitFormData.append("facts[" + i + "][fact_value]", factsValue[i]);
      }
    }


    [
      "departures",
      "facts",
      "categories",
      "faqs",
      "trip_info",
      "group_faqs",
      "facilities"
    ].forEach((e) => delete data[e]);

    Object.keys(data).forEach((item, i) => {
      webKitFormData.append(item, data[item]);
    });

    await axios
      .post(`${BASE_URL}api/v1/admin/hotel/room/add`, webKitFormData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: ROOM_CREATED,
          payload: response,
        });
        dispatch({
          type: UPDATED_RECENTLY,
        });
        message.success(response.data.message);
        if (exitNow) {
          history.push(`/hotel/room/update/${response.data.data.id}`);
        }
      })
      .catch(function (error) {
        dispatch({
          type: ERROR_ROOM_CREATE,
          payload: error.response,
        });
        message.error(error.response.data.message);
      });
  };

export const updateEntityInfo =
  (formData, tokenData, id) => async (dispatch) => {
    dispatch({
      type: UPDATING_ROOM,
    });

    let data = Object.fromEntries(
      Object.entries(formData)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== "")
        .filter(([_, v]) => v !== undefined)
    );
    let webKitFormData = new FormData();

    if (data.facilities) {
      for (let i = 0; i < data.facilities.length; i++) {
        webKitFormData.append("facilities["+i+"]", data.facilities[i]);
      }
    }
    if (data.faqs) {
      for (let i = 0; i < data.faqs.length; i++) {
        webKitFormData.append("faqs[]", data.faqs[i]);
      }
    }
    if (data.group_faqs) {
      for (let i = 0; i < data.group_faqs.length; i++) {
        webKitFormData.append("group_faqs[]", data.group_faqs[i]);
      }
    }
    if (data.facts) {
      let factsTitle = data.facts.map((item) => item.fact_title);
      let factsValue = data.facts.map((item) => item.fact_value);
      let factsIconKey = data.facts.map((item) => item.fact_icon_key);
      let factsId = data.facts.map((item) => item.id);
      for (let i = 0; i < factsTitle.length; i++) {
        webKitFormData.append("facts[" + i + "][fact_title]", factsTitle[i]);
      }
      for (let i = 0; i < factsValue.length; i++) {
        webKitFormData.append("facts[" + i + "][fact_value]", factsValue[i]);
      }
      for (let i = 0; i < factsIconKey.length; i++) {
        webKitFormData.append("facts[" + i + "][fact_icon_key]", factsIconKey[i]);
      }
      for (let i = 0; i < factsId.length; i++) {
        webKitFormData.append("facts[" + i + "][id]", factsId[i]);
      }
    }

    [
      "departures",
      "facts",
      "categories",
      "faqs",
      "trip_info",
      'facilities'
    ].forEach((e) => delete data[e]);

    Object.keys(data).forEach((item, i) => {
      webKitFormData.append(item, data[item]);
    });
    webKitFormData.append("_method", "put");

    await axios
      .post(
        `${BASE_URL}api/v1/admin/hotel/room/edit/${id}`,
        webKitFormData,
        {
          headers: {
            Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
          },
        }
      )
      .then(function (response) {
        dispatch({
          type: ROOM_UPDATED,
          payload: response,
        });
        dispatch({
          type: UPDATED_RECENTLY,
        });
        message.success(response.data.message);
      })
      .catch(function (error) {
        dispatch({
          type: ERROR_ROOM_CREATE,
          payload: error.response,
        });
        message.error(error.response.data.message);
      });
  };
