
import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import DashboardList from "../../../Components/Layouts/DashboardList";
import { getList as getCategoryList, deleteEntityById } from "../../../Redux/Hotel/ServiceMenu/actions";
import { getList as getCategoryTypeList } from "../../../Redux/Hotel/ServiceMenuType/actions";
import clean from 'lodash-clean'

function List({ useAuth, loginData, getList, list, getTypeList, listType, onDelete }) {
  let tokenData = loginData.data;
  const [filter_options, setFilterOptions] = useState();

  useEffect(() => {
    if (!listType.data) {
      getTypeList(tokenData);
    }
    if(listType.data){
       setFilterOptions(listType.data?.data.map(e=>{return{value:e.id, text:e.category_type}}))
    }

  }, [list.data, listType.data]);
  
  const dataList = clean(list)

  const tableConfig = {
    title: "Cuisine",
    filterOptionTitle: "Cuisine Type",
    filterOptions: filter_options,
    applyFilter: true,
    tableColumns: [
      {name: "Title", selector: 'type_title', width: '77%'},
      {name: "Status", selector: 'status', width: '3%'},
      {name: "Modified Date", selector: 'updated_at', width: '15%'},
      {name: "ID", selector: 'id', width: '5%'}
    ],
    filterByColumn: 'type_id',
    searchByColumn: 'type_title',   
    addPath: "/hotel/service-menu/add",
    updatePath: "/hotel/service-menu/update",
    addTypePath: "/hotel/service-menu-type/add",
    addTypeText: "Add Cuisine Type",
    noDataMessage: "No Cuisine exists",
    deleteBtnText: 'Delete',
    updateLinkColumn: 'type_title',
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
  }
  console.log(list);
  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={dataList}
      tableConfig={tableConfig}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.category,
    listType: state.categoryType,
    loginData: state.loginData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getCategoryList(tokenData)),
    getTypeList: (tokenData) => dispatch(getCategoryTypeList(tokenData)),
    onDelete: (idList) => dispatch(deleteEntityById(idList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
