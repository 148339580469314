import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Col,
  Row,
  Switch,
  InputNumber,
  Alert,
  Upload,
  Spin,
  Tabs,
} from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import slugify from "react-slugify";
import TextEditor from "../../Core/ReactTinymce/ReactTinymce";
import ImageCrop from "../../ImageCrop";
import ImageField from "../../Core/ImageField";
import { getList as getAmenitiesList } from '../../../Redux/Hotel/Amenities/actions'

//import "antd/dist/antd.css";
import TextArea from "antd/lib/input/TextArea";
import { UploadOutlined } from "@ant-design/icons";
import { BASE_URL } from "../../../Redux/urls";

const { Option } = Select;
const RoomForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
  getAmenitiesList,
  amenitiesList
}) => {
  let history = useHistory();
  const tokenData = loginData.data;
  const [pageTitle, setPageTitle] = useState('')
  const [package_detail, setPackageDetails] = useState();
  const [featured_image, setFeaturedImage] = useState(
    isUpdateForm &&
    data.dataById &&
    data.dataById.data.featured_image &&
    data.dataById.data.featured_image.full_path
  );
  const [bannerImage, setBannerImage] = useState();
  const [image_crop_open, setImageCropOpen] = useState(false);

  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const loading =
    selectedForm.loading

    const loadEntityById = async () => {
      getEntityById(tokenData, params.id);
    };

  useEffect(() => {
   

    const LoadAmenities = async () => {
      getAmenitiesList(tokenData);
    };

    if (isUpdateForm) {
      loadEntityById();
    }
    if (!amenitiesList.data) {
      LoadAmenities();
    }
  }, [amenitiesList.data, isUpdateForm, getAmenitiesList, getEntityById, params?.id, tokenData]);


  const amenitiesOptions = amenitiesList.data?.data?.map((itm) => {
    return {
      label: itm.facility_title,
      value: itm.id
    }
  })


  useEffect(() => {
    const alert = document.querySelector(".custom-alert");
    setTimeout(() => {
      alert.classList.add("fadeout");
    }, 5000);
  }, [selectedForm.error]);

  if (isUpdateForm) {

    const add_banner = data.dataById?.data.banners?.map((a) => {
      return {
        ...a,
        banner_alt: a.alt_text,
        banner_caption: a.caption,
        banner_image: {
          file: new FileReader(BASE_URL + a.full_path),
          fileList: [
            {
              uid: a.id,
              name: a.full_name,
              url: BASE_URL + a.full_path,
            },
          ],
        },
      };
    });

    setFieldsValue({
      scheme_title: data.dataById?.data.scheme_title,
      url_title: data.dataById?.data.urlinfo?.url_title,
      slug: data.dataById?.data.urlinfo?.url_slug,
      scheme_details: data.dataById?.data.scheme_details,
      scheme_excerpt: data.dataById?.data.scheme_excerpt,
      scheme_price: data.dataById?.data.scheme_price,
      //room_offer_price: data.dataById?.data.room_offer_price,
      scheme_duration: data.dataById?.data.scheme_duration,
      scheme_duration_type: data.dataById?.data.scheme_duration_type,
      status: data.dataById?.data.status === 1 ? true : false,
      is_featured: data.dataById?.data.is_featured === 1 ? true : false,
      //room_availability: data.dataById?.data.room_availability === 1 ? true : false,
      meta_title: data.dataById?.data.meta?.meta_title,
      meta_keywords: data.dataById?.data.meta?.meta_keywords,
      meta_description: data.dataById?.data.meta?.meta_description,
      featured_image: data.dataById?.data.featured?.thumb_path,
      featured_alt: data.dataById?.data.featured?.alt_text,
      featured_caption: data.dataById?.data.featured?.caption,
      // facts: data.dataById?.data?.additional_facts,
      banners: add_banner,
      banner_alt: data.dataById?.data.banner?.alt_text,
      banner_caption: data.dataById?.data.banner?.caption,
      banner_image: data.dataById?.data.banner?.full_path,
      additional_field_1: data.dataById?.data.additional_field_1,
      additional_field_2: data.dataById?.data.additional_field_2,
    });
  }

  const packageAction = (e, action) => {
    form.validateFields().then((values) => {
      const axios = require("axios");
      if (action === "add") {
        if (featured_image) {
          axios
            .get(featured_image, { responseType: "blob" })
            .then((response) => {
              createEntity(
                {
                  ...values,
                  status: values.status ? 1 : 0,
                  is_featured: values.is_featured ? 1 : 0,
                  featured_image: response.data,
                  ...(bannerImage && { banner_image: bannerImage.file }),
                },
                tokenData
              )
            });
        } else {
          createEntity(
            {
              ...values,
              status: values.status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
              ...(bannerImage && { banner_image: bannerImage.file }),
            },
            tokenData
          );
        }
      } else if (action === "addandexit") {
        if (featured_image) {
          axios
            .get(featured_image, { responseType: "blob" })
            .then((response) => {
              createEntity(
                {
                  ...values,
                  status: values.status ? 1 : 0,
                  is_featured: values.is_featured ? 1 : 0,
                  featured_image: response.data,
                  ...(bannerImage && { banner_image: bannerImage.file }),
                },
                tokenData,
                true,
                history
              );
            });
        } else {
          createEntity(
            {
              ...values,
              status: values.status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
              ...(bannerImage && { banner_image: bannerImage.file }),
            },
            tokenData,
            true,
            history
          );
        }
      } else if (action === "update") {
        if (featured_image) {
          axios
            .get(featured_image, { responseType: "blob" })
            .then((response) => {
              updateEntityInfo(
                {
                  ...values,
                  status: values.status ? 1 : 0,
                  is_featured: values.is_featured ? 1 : 0,
                  featured_image: response.data,
                  ...(bannerImage && { banner_image: bannerImage.file }),
                },
                tokenData,
                params.id
              ).then(() => {
                loadEntityById()
              })
            });
        } else {
          updateEntityInfo(
            {
              ...values,
              status: values.status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
              ...(bannerImage && { banner_image: bannerImage.file }),
            },
            tokenData,
            params.id
          ).then(() => {
            loadEntityById()
          })
        }
      }
    });
  };

  const addNewEntry = (e) => {
    packageAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    packageAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    packageAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
    setPackageDetails("");
    setFeaturedImage("");
  };

  const actionBarOptions = {
    titleToAdd: "Add Package",
    titleToUpdate: isUpdateForm && data.dataById?.data.package_title,
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update",
    discardLink: "hotel/packages",
    pageUrl: isUpdateForm && "/" + data.dataById?.data.urlinfo?.url_slug,
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  const OperationsSlot = {
    left: (
      <a
        className="btn-icon"
        onClick={() => history.push(`/${actionBarOptions.discardLink}`)}
      >
        <i className="bi-arrow-left-circle"></i>
      </a>
    ),
    // right: (

    // )
  };

  return (
    <>

      <div className="page-header">
        <div className="icon">
          <span
            className="btn-icon"
            onClick={() => history.push(`/${actionBarOptions.discardLink}`)}
          >
            <i className="bi-arrow-left-circle"></i>
          </span>

        </div>
        <div className="text">
          <h1>{isUpdateForm ? data.dataById?.data.scheme_title : pageTitle} {isUpdateForm && <a href={process.env.REACT_APP_FRONT_URL + data.dataById?.data?.urlinfo.url_slug} target="_blank"><i className="bi-link" /></a>}</h1>
        </div>
      </div>

      {selectedForm?.error && (
        <Alert
          className="custom-alert"
          message="Error"
          showIcon
          description={[
            <ul>
              {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                return <li key={idx}>{itm}</li>;
              })}
            </ul>,
          ]}
          type="error"
          closable
        />
      )}

      {
        isUpdateForm && <Tabs defaultActiveKey="1" >
          <Tabs.TabPane tab="Fundamental" key="1">
            <Form
              form={form}
              size="large"
              layout="vertical"
              onFinish={actionBarOptions.updateExistingEntry}
              className="custom-form"
              wrapperCol={{
                flex: 1,
              }}
            >
              {/* <DashboardActionBar {...{ actionBarOptions }} /> */}


              <Spin spinning={loading} delay={500}>


                <div className="common-module bg-white">
                  <Row
                    gutter={{ xs: 8, sm: 16, md: 24 }}
                  >
                    <Col className="gutter-row" xs={24} xl={16}>
                      <Form.Item
                        label="Title"
                        name="scheme_title"
                        rules={[
                          {
                            required: true,
                            message: "Title is required",
                          }
                        ]}
                      >
                        <Input onChange={(e) => setPageTitle(e.target.value)} />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={16}>
                      <Form.Item
                        label="Url Title"
                        name="url_title"
                        rules={[
                          {
                            required: true,
                            message: "Url title is required",
                          }
                        ]}
                      >
                        <Input
                          onChange={(e) =>
                            form.setFieldsValue({ slug: slugify(e.target.value) })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={16}>
                      <Form.Item
                        label="Meta Title"
                        name="meta_title"
                        rules={[
                          {
                            required: true,
                            message: "Meta title is required",
                          },
                          { min: 5, max: 255, message: "Invalid meta title" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={24} xl={16}>
                      <Form.Item
                        label="Price (USD)"
                        name="scheme_price"
                        className="noLabel"
                        rules={[
                          {
                            required: true,
                            message: "Price is required",
                          }
                        ]}
                      >
                        <InputNumber />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={24} xl={16}>
                      <Form.Item
                        label="Durations"
                        name="scheme_duration"
                        rules={[
                          {
                            required: true,
                            message: "Package Duration is required",
                          },
                        ]}
                      >
                        <InputNumber
                          addonAfter={
                            <Form.Item
                              noStyle
                              name="scheme_duration_type"
                              initialValue="days"
                            >
                              <Select style={{ width: 100 }}>
                                <Option value="days">Days</Option>
                                <Option value="hrs">Hours</Option>
                              </Select>
                            </Form.Item>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={16}>
                      <Form.Item
                        label={
                          <span className="ant-form-item-optional">
                            Auto Generate from Url Title
                          </span>
                        }
                        name="slug"
                        title="Auto Generate from Url Title"
                        rules={[
                          {
                            required: true,
                            message: "Slug is required",
                          },
                        ]}
                      >
                        <Input readOnly />
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={24} xl={16}>
                      <div className="switch-group">
                        <Form.Item
                          label="Status"
                          valuePropName="checked"
                          name="status"
                        >
                          <Switch
                            checkedChildren="Publish"
                            unCheckedChildren="Unpublish"
                          />
                        </Form.Item>
                        <Form.Item
                          label="Is Featured?"
                          valuePropName="checked"
                          name="is_featured"
                        >
                          <Switch
                            checkedChildren="Featured"
                            unCheckedChildren="Not Featured"
                          />
                        </Form.Item>

                      </div>
                    </Col>

                    <Col className="gutter-row" xs={24} xl={24}>
                      <Form.Item label="Description" name="scheme_details">
                        <TextEditor
                          value={package_detail}
                          initialValue={isUpdateForm && package_detail}
                          onEditorChange={(e) => {
                            setPackageDetails(e.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <button htmlType="submit"
                    disabled={actionBarOptions.loading}
                    className="btn btn-primary btn-save"> <i className="bi bi-save2"></i>Save Changes</button>
                </div>
              </Spin>
            </Form>

          </Tabs.TabPane>
          <Tabs.TabPane tab="Media" key="2">
            <Form
              form={form}
              size="large"
              layout="vertical"
              onFinish={actionBarOptions.updateExistingEntry}
              className="custom-form"
              wrapperCol={{
                flex: 1,
              }}
            >
              <Spin spinning={loading} delay={500}>

                <div className="common-module bg-white">
                  <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
                    <Col className="gutter-row" xs={24} xl={16}>
                      <Form.Item>
                        <ImageField
                          title="Featured Image"
                          image={
                            featured_image ||
                            (isUpdateForm &&
                              data.dataById &&
                              data.dataById.data.featured &&
                              data.dataById.data.featured.full_path)
                          }
                          height={process.env.REACT_APP_PACKAGE_THUMBINAL_HEIGHT}
                          width={process.env.REACT_APP_PACKAGE_THUMBINAL_WIDTH}
                          setImage={(image_data) => {
                            console.log(image_data.File);
                            setFeaturedImage(image_data);
                            setImageCropOpen(true);
                          }}
                        />
                        {featured_image && image_crop_open && (
                          <ImageCrop
                            height={
                              process.env.REACT_APP_PACKAGE_THUMBINAL_HEIGHT
                            }
                            width={process.env.REACT_APP_PACKAGE_THUMBINAL_WIDTH}
                            image={featured_image}
                            setCroppedImage={setFeaturedImage}
                            setImageCropOpen={setImageCropOpen}
                          />
                        )}
                      </Form.Item>

                      <Form.Item
                        style={{ marginTop: "-20px" }}
                        label="Alt Text"
                        name="featured_alt"
                        rules={[{ min: 5, max: 50, message: "Invalid Alt Text" }]}
                      >
                        <Input />
                      </Form.Item>
                      <Row gutter={10}>

                      </Row>
                      <Form.Item
                        label="Caption"
                        name="featured_caption"
                        rules={[{ min: 5, max: 50, message: "Invalid caption" }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row " xs={24} xl={16}>
                      <div className="common-module">
                        <h6>Banner Image</h6>
                        <Row gutter={10}>
                          <Col xs={24}>
                            <Form.Item
                              name="bannerImage"
                              style={{ marginBottom: "0" }}
                            >
                              <Upload
                                listType="picture-card"
                                maxCount={1}
                                name="tet"
                                beforeUpload={(file) => {
                                  return false;
                                }}
                                onChange={(file) => setBannerImage(file)}
                              >
                                {isUpdateForm &&
                                  data.dataById?.data.banner?.full_path &&
                                  !bannerImage ? (
                                  <img
                                    src={
                                      BASE_URL + data.dataById?.data.banner?.full_path
                                    }
                                  />
                                ) : (
                                  <UploadOutlined style={{ fontSize: "27px" }} />
                                )}
                              </Upload>
                              <span className="text-muted">
                                Size should be {process.env.REACT_APP_ROOM_BANNER_WIDTH + 'x' + process.env.REACT_APP_ROOM_BANNER_HEIGHT}
                              </span>
                            </Form.Item>
                          </Col>

                          <Col xs={24} lg={8}>
                            <Form.Item label="Banner Alt" name="banner_alt">
                              <Input placeholder="Alt Text" />
                            </Form.Item>
                          </Col>

                          <Col xs={24} lg={16}>
                            <Form.Item label="Banner Caption" name="banner_caption">
                              <Input placeholder="banner caption" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Form.Item>
                    <button htmlType="submit"
                      disabled={actionBarOptions.loading}
                      className="btn btn-primary btn-save"> <i className="bi bi-save2"></i>Save Changes</button>
                  </Form.Item>
                </div>
              </Spin>
            </Form>

          </Tabs.TabPane>
          <Tabs.TabPane tab="Meta" key="3">
            <Form
              form={form}
              size="large"
              layout="vertical"
              onFinish={actionBarOptions.updateExistingEntry}
              className="custom-form"
              wrapperCol={{
                flex: 1,
              }}
            >
              <Spin spinning={loading} delay={500}>

                <div className="common-module bg-white">
                  <Form.Item
                    label="Meta Title"
                    name="meta_title"
                    rules={[
                      {
                        required: true,
                        message: "Meta title is required",
                      },
                      { min: 5, max: 255, message: "Invalid meta title" },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Meta Keywords"
                    name="meta_keywords"
                    rules={[
                      {
                        min: 5,
                        max: 255,
                        message: "Invalid meta keywords",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    className="mb-0"
                    label="Meta Description"
                    name="meta_description"
                  >
                    <TextArea rows={8} />
                  </Form.Item>

                  <button htmlType="submit" style={{ marginTop: '24px' }}
                    disabled={actionBarOptions.loading}
                    className="btn btn-primary btn-save"> <i className="bi bi-save2"></i>Save Changes</button>
                </div>
              </Spin>
            </Form>

          </Tabs.TabPane>
        </Tabs>
      }

      {
        isAddForm &&
        <Form
          form={form}
          size="large"
          layout="vertical"
          //onFinish={actionBarOptions.createEntity}
          className="custom-form"
          wrapperCol={{
            flex: 1,
          }}
        >
          {/* <DashboardActionBar {...{ actionBarOptions }} /> */}


          <Spin spinning={loading} delay={500}>


            <div className="common-module bg-white">
              <Row
                gutter={{ xs: 8, sm: 16, md: 24 }}
              >
                <Col className="gutter-row" xs={24} xl={16}>
                  <Form.Item
                    label="Title"
                    name="scheme_title"
                    rules={[
                      {
                        required: true,
                        message: "Title is required",
                      }
                    ]}
                  >
                    <Input onChange={(e) => setPageTitle(e.target.value)} />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} xl={16}>
                  <Form.Item
                    label="Url Title"
                    name="url_title"
                    rules={[
                      {
                        required: true,
                        message: "Url title is required",
                      }
                    ]}
                  >
                    <Input
                      onChange={(e) =>
                        form.setFieldsValue({ slug: slugify(e.target.value) })
                      }
                    />
                  </Form.Item>
                </Col>

                <Col className="gutter-row" xs={24} xl={16}>
                  <Form.Item
                    label={
                      <span className="ant-form-item-optional">
                        Auto Generate from Url Title
                      </span>
                    }
                    name="slug"
                    title="Auto Generate from Url Title"
                    rules={[
                      {
                        required: true,
                        message: "Slug is required",
                      },
                    ]}
                  >
                    <Input readOnly />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} xl={16}>
                  <Form.Item
                    label="Meta Title"
                    name="meta_title"
                    rules={[
                      {
                        required: true,
                        message: "Meta title is required",
                      },
                      { min: 5, max: 255, message: "Invalid meta title" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col className="gutter-row" xs={24} xl={16}>
                  <Form.Item
                    label="Price (USD)"
                    name="scheme_price"
                    className="noLabel"
                    rules={[
                      {
                        required: true,
                        message: "Price is required",
                      }
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                </Col>

                <Col className="gutter-row" xs={24} xl={16}>
                  <Form.Item
                    label="Durations"
                    name="scheme_duration"
                    rules={[
                      {
                        required: true,
                        message: "Package Duration is required",
                      },
                    ]}
                  >
                    <InputNumber
                      addonAfter={
                        <Form.Item
                          noStyle
                          name="scheme_duration_type"
                          initialValue="days"
                        >
                          <Select style={{ width: 100 }}>
                            <Option value="days">Days</Option>
                            <Option value="hrs">Hours</Option>
                          </Select>
                        </Form.Item>
                      }
                    />
                  </Form.Item>
                </Col>

                <Col className="gutter-row" xs={24} xl={16}>
                  <div className="switch-group">
                    <Form.Item
                      label="Status"
                      valuePropName="checked"
                      name="status"
                    >
                      <Switch
                        checkedChildren="Publish"
                        unCheckedChildren="Unpublish"
                      />
                    </Form.Item>
                    <Form.Item
                      label="Is Featured?"
                      valuePropName="checked"
                      name="is_featured"
                    >
                      <Switch
                        checkedChildren="Featured"
                        unCheckedChildren="Not Featured"
                      />
                    </Form.Item>

                  </div>
                </Col>

                <Col className="gutter-row" xs={24} xl={24}>
                  <Form.Item label="Description" name="scheme_details">
                    <TextEditor
                      value={package_detail}
                      initialValue={isUpdateForm && package_detail}
                      onEditorChange={(e) => {
                        setPackageDetails(e.target.value);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <button htmlType="submit"
                onClick={actionBarOptions.addNewEntryAndExistToList}
                disabled={actionBarOptions.loading}
                className="btn btn-primary btn-save"> <i className="bi bi-save2"></i>Save Changes</button>
            </div>
          </Spin>
        </Form>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    amenitiesList: state.amenities
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAmenitiesList: (tokenData) => dispatch(getAmenitiesList(tokenData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomForm);
